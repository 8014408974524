<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="商家名称">
        <el-input v-model="queryForm.user"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="queryForm.status" placeholder="请选择">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" max-height="500px" style="width: 100%;">
      <el-table-column type="index" label="#" width="60"></el-table-column>
      <el-table-column label="商家名称" prop="name"></el-table-column>
      <el-table-column label="标志" v-slot="{ row }">
        <span v-if="!row.photo">无</span>
        <el-image v-else :src="row.photo" fit="cover" class="img"></el-image>
      </el-table-column>
      <el-table-column label="描述" prop="desc"></el-table-column>
      <el-table-column label="营业时间" prop="business_time"></el-table-column>
      <el-table-column label="状态" v-slot="{ row }">
        <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
        <el-tag v-else type="info" size="small">禁用</el-tag>
      </el-table-column>
      <el-table-column label="操作" v-slot="{ row }">
        <el-link @click="handleEdit(row)" type="primary">编辑</el-link>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="queryForm.page_size" @current-change="getMerchantList">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px" :before-close="close" top="1vh">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="商家名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="商家标志" prop="photo">
          <el-upload list-type="picture-card" :limit="1" :action="UPLOAD_URL" :file-list="logoFileList"
            :before-upload="imgBeforeUpload" :on-success="logoUploadSussess" :on-remove="logoRemove"
            :on-exceed="logoExceed">
            <i class="el-icon-plus"></i>
            <div slot="tip">请上传png、jpg格式的图片，数量限制：1</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="form.desc" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="营业时间" prop="business_time">
          <el-input v-model="form.business_time" placeholder="例: 7:00-21:00"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" active-color="#13ce66"
            inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getMerchantListAPI,
    saveMerchantAPI,
  } from '@/api/order.js'

  import {
    UPLOAD_URL
  } from '@/utils/constant.js'

  export default {
    name: 'Merchant',
    data() {
      return {
        UPLOAD_URL,

        // 搜索相关
        queryForm: {
          page: 1,
          status: 1,
          page_size: 10,
          key: ''
        },

        // 商家标志照片
        logoFileList: [],

        // 店铺表单相关
        submitting: false,
        dialogVisible: false,
        dialogTitle: '',
        form: {
          name: '',
          photo: '',
          desc: '',
          business_time: '',
          status: 1
        },
        rules: {
          name: [{
            required: true,
            message: '店名不能为空',
            trigger: 'blur'
          }],
          photo: [{
            required: true,
            message: '店铺标识不能为空',
          }],
          desc: [{
            required: true,
            message: '描述不能为空',
            trigger: 'blur'
          }],
          business_time: [{
            required: true,
            message: '营业时间不能为空',
            trigger: 'blur'
          }],
        },

        // 店铺列表相关
        tableData: [],
        tableLoading: false,
        total: 0,
        curRowId: '',
      }
    },
    created() {
      this.getMerchantList()
    },
    methods: {
      handleAdd() {
        this.dialogTitle = '新增'
        this.dialogVisible = true
      },
      handleEdit(row) {
        this.dialogTitle = '编辑'
        this.dialogVisible = true
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          if (key == 'photo') continue
          this.form[key] = copyRow[key]
        }
        // 回显商家标志
        if (copyRow.photo) {
          this.logoFileList = [{
            url: copyRow.photo
          }]
        }
      },
      close() {
        this.fileList = []
        this.logoFileList = []
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      handleSubmit() {
        // 设置商家标志
        this.form.photo = this.getFileStr(this.logoFileList)
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveMerchantAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.close()
            this.getMerchantList()
          }).finally(() => this.submitting = false)
        })
      },
      // return fileStr
      getFileStr(fileList) {
        let fileStr = ''

        if (fileList.length) {
          let files = fileList.map(item => {
            // 判断数组项有无 response 字段，如果有说明该项是新上传的
            if (item.response) return item.response.data.url
            else return item.url
          })
          fileStr = files.toString()
        }
        return fileStr
      },
      imgBeforeUpload(file) {
        if (file.type != 'image/png' && file.type != 'image/jpeg') {
          this.$message.error('文件格式错误')
          return false
        }
      },
      logoUploadSussess(res, file, fileList) {
        this.logoFileList = fileList
      },
      logoRemove(file, fileList) {
        this.logoFileList = fileList
      },
      logoExceed() {
        return this.$message.error('数量超出限制，如要继续上传，请删除现有的logo')
      },
      getMerchantList() {
        this.tableLoding = true

        getMerchantListAPI(this.queryForm).then(res => {
          this.tableLoding = false
          this.tableData = res.data
          this.total = res.total
        })
      }
    }
  }
</script>

<style scoped>
  .img {
    width: 100px;
    height: 100px;
  }
</style>